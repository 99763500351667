#appcard {
  display: inline-block;
  margin: 10px;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  padding: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#noncopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.fotos {
  /*margin: 10px;*/
  transition: all 1s;
}
.fotos:hover {
  transform: scale(1.1);
}
/* img:hover {
  transform: scale(1.1); bu butun fotograflara efekt veriyor.
} */
.breakline {
  height: 1px;
  width: 1010px;
  background-color: darkgray;
}
.foto1,
.foto2,
.foto3,
.foto4,
.foto5 {
  max-width: 26rem;
  max-height: 26rem;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}
.container {
  max-width: 40rem;
  width: 90%;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 20px;
}
input[type="submit"] {
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* input[type="submit"]:hover {
  background-color: #45a049;
} */

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery img {
  width: 600px;
  height: auto;
  margin: 10px;
  transition: all 0.5s;
}
.gallery img:hover {
  transform: scale(1.05);
}
